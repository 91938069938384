<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <v-dialog persistent v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <v-btn
                        color="primary"
                        dark
                        class="ml-auto ma-3 mr-1"
                        @click="refreshData"
                    >
                      <i style="color: #fff" class="flaticon2-refresh-1"></i>
                    </v-btn>
                    <v-btn
                        v-if="hasPermission('filtervalues.create')"
                        color="primary"
                        dark
                        class="ma-3 ml-0"
                        v-on="on"
                    >
                      <i class="flaticon2-plus text-white"></i>
                    </v-btn>
                  </div>
                </template>
                <v-card>
                  <i class="fas fa-times custom-times" @click="closeDialog"></i>
                  <v-card-title>
                    <span v-if="editedItem.id"
                    >Edit {{ editedItem.title }}</span
                    >
                    <span v-else>Create Filter value</span>
                  </v-card-title>
                  <v-card-text>
                    <v-tabs background-color="deep-purple accent-4" left dark>
                      <v-tabs-slider></v-tabs-slider>
                      <v-tab>Details</v-tab>
                      <v-tab>Media</v-tab>
                      <v-tab-item>
                        <v-card flat>
                          <v-card-text>
                            <v-form
                                ref="form"
                                v-model="validationRules.valid"
                                lazy-validation
                            >
                              <v-row>
                                <v-col cols="12" sm="12">
                                  <v-select
                                      clearable
                                      :items="filtersFilter"
                                      v-model="editedItem.filter_id"
                                      item-text="title"
                                      :rules="validationRules.filtersRules"
                                      item-value="id"
                                      label="Filter*"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      v-model="editedItem.title"
                                      :counter="100"
                                      :rules="validationRules.titleRules"
                                      label="Title*"
                                      required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-select
                                      clearable
                                      v-model="editedItem.featured"
                                      :items="ques"
                                      item-text="title"
                                      item-value="value"
                                      label="Featured"
                                  >
                                  </v-select>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <ModuleMedia
                            moduleType="image"
                            moduleOf="filter_value"
                            moduleMultiple="false"
                            :moduleImages="moduleImages"
                            :moduleImagesOld="moduleImagesOld"
                        ></ModuleMedia>
                      </v-tab-item>
                    </v-tabs>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog"
                    >Cancel
                    </v-btn
                    >
                    <v-btn
                        :disabled="!validationRules.valid"
                        color="blue darken-1"
                        text
                        @click="saveItem(editedItem)"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                  <v-col cols="12">
                    <div
                        v-if="errors != undefined && errors.length >= 0"
                        role="alert"
                        v-bind:class="{ show: errors.length }"
                        class="alert fade alert-danger"
                    >
                      <div
                          class="alert-text"
                          v-for="(error, i) in errors"
                          :key="i"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <hr style="margin:0"/>
              <v-skeleton-loader
                  :loading="loading"
                  v-if="isLoaded"
                  type="table"
              >
              </v-skeleton-loader>
              <v-card-title>
                Filter Values ({{ totalRecords }})
                <v-spacer></v-spacer>
                <v-text-field
                    @keydown.enter="searchQueryData"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                  :headers="headers"
                  :items="items"
                  :options.sync="pagination"
                  :server-items-length="totalRecords"
                  :footer-props="{
                  itemsPerPageOptions: rowsPerPageItems,
                  showFirstLastPage: true
                }"
                  :loading="loading"
                  v-show="!isLoaded"
                  mobile-breakpoint="800"
                  class="elevation-0"
              >
                <template v-slot:item.featured="{ item }">
                  <div class="text-truncate">
                    <p v-if="item.featured == 0">No</p>
                    <p v-if="item.featured == 1">Yes</p>
                  </div>
                </template>
                <template v-slot:item.image_public_url="{ item }">
                  <div class="text-truncate">
                    <img :src="item.image_public_url" style="width: 50%"/>
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="text-truncate">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            v-if="hasPermission('filtervalues.edit')"
                            color="primary"
                            @click="showEditDialog(item)"
                            dark
                            class="mr-2"
                            v-on="on"
                        >
                          <v-icon dark small>fas fa-edit</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            v-if="hasPermission('filtervalues.delete')"
                            color="red"
                            dark
                            @click="deleteItem(item)"
                            class="mr-2"
                            v-on="on"
                        >
                          <v-icon dark small>fas fa-trash</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
              <!-- this dialog is used for both create and update -->
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import ModuleMedia from "@/view/pages/admin/media/ModuleMedia";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {
  FILTERVALUES_LIST,
  CREATE_FILTERVALUE,
  UPDATE_FILTERVALUE,
  CLEAR_FILTERVALUE_ERRORS,
  DELETE_FILTERVALUE,
  GET_FILTERVALUE_DETAILS
} from "@/core/services/store/filtervalues.module";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";
import {FILTERS_SIMPLE_LIST} from "@/core/services/store/filters.module";
import {bus} from "@/main";

export default {
  name: "filtervalues",
  components: {
    ModuleMedia
  },
  data() {
    return {
      headers: [
        {
          text: "Image",
          value: "image_public_url",
          name: "image_public_url",
          filterable: false,
          sortable: false
        },
        {
          text: "Title",
          value: "title",
          name: "title",
          filterable: true,
          sortable: false
        },
        {
          text: "Filter",
          value: "filter.title",
          name: "filter.title",
          filterable: true,
          sortable: false
        },
        {
          text: "Featured",
          value: "featured",
          name: "featured",
          filterable: true,
          sortable: false
        },
        {
          text: "Actions",
          value: "actions",
          name: "actions",
          filterable: false,
          sortable: false
        }
      ],
      isLoaded: true,
      draw: 1,
      searchQuery: "",
      loading: true,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 10,
        sortBy: 0,
        totalItems: 0
      },
      totalRecords: 0,
      rowsPerPageItems: [10, 15, 20, 30, 40, 50],
      columns: [],
      moduleImages: [],
      moduleImagesOld: [],
      validationRules: {
        valid: true,
        titleRules: [
          v => !!v || "This field is required",
          v =>
              (v && v.length <= 100) ||
              "Must be less than or equal to 100 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        filtersRules: [v => !!v || "This field is required"]
      },
      items: [],
      filtersFilter: [],
      dialog: false,
      editedItem: {},
      ques: [
        {value: "0", title: "No"},
        {value: "1", title: "Yes"}
      ]
    };
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_FILTERVALUE_ERRORS);
      if (!val) this.editedItem = {};
      if (!val) this.moduleImages = [];
      if (!val) this.moduleImagesOld = [];
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          "&columns[" +
          i +
          "][data]=" +
          this.headers[i].value +
          "&columns[" +
          i +
          "][name]=" +
          this.headers[i].name +
          "&columns[" +
          i +
          "][searchable]=" +
          this.headers[i].filterable +
          "&columns[" +
          i +
          "][orderable]=" +
          this.headers[i].sortable +
          "&columns[" +
          i +
          "][search][value]=" +
          "&columns[" +
          i +
          "][search][regex]=" +
          false
      );
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Filters Management", route: "filter-values"},
      {title: "Filter values"}
    ]);
    bus.$on("moduleImagesUploaded", moduleImages => {
      this.moduleImages = moduleImages.arr;
    });
    bus.$on("moduleImagesUploadedOld", moduleImagesOld => {
      this.moduleImagesOld = moduleImagesOld.arr;
    });
    this.getFilters();
  },
  methods: {
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise(resolve => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fecth per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params)
            .map(key => {
              return (
                  encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
              );
            })
            .join("&");
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            params.sortBy +
            "&order[0][dir]=" +
            (params.descending ? "desc" : "asc");
        this.$store
            .dispatch(FILTERVALUES_LIST, {q: query, columns: this.columns})
            .then(res => {
              this.draw++;
              let items = res.records.data;
              let total = res.records.recordsFiltered;
              this.loading = !this.loading;
              this.isLoaded = !this.isLoaded;
              resolve({
                items,
                total
              });
            });
      });
    },
    refreshData() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
        this.getFilters();
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showEditDialog(item) {
      this.$store.dispatch(GET_FILTERVALUE_DETAILS, item.id).then(data => {
        let oImages = [];
        this.editedItem = data.records || {};
        if (data.records.image_public_url !== null) {
          oImages.push(data.records);
        }
        this.moduleImagesOld = oImages;
        this.dialog = !this.dialog;
      });
    },
    closeDialog() {
      if (this.moduleImages.length > 0) {
        let mFiles = [];
        let that = this;
        mFiles = this.moduleImages;
        this.$store.dispatch(SET_CONFIRMATION, {
          title: "Are you sure?",
          msg: "All unsaved data will be lost",
          clr: "red",
          callback: function () {
            that.$store
                .dispatch("delete_uploaded_image", {
                  files: mFiles,
                  type: "modal"
                })
                .then(() => {
                  that.moduleImages = [];
                  that.dialog = false;
                  bus.$emit("moduleImagesDeletedByModal");
                });
          }
        });
      } else {
        this.dialog = !this.dialog;
      }
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        /* this is used for both creating and updating API records
       the default method is POST for creating a new item */
        let method = CREATE_FILTERVALUE;
        let id = item.id;
        this.$set(item, "image", this.moduleImages);
        let data = {
          data: item
        };
        if (id) {
          // if the item has an id, we're updating an existing item
          method = UPDATE_FILTERVALUE;
          // delete data.data.id;
        }
        this.$store.dispatch(CLEAR_FILTERVALUE_ERRORS);
        // save the record
        this.$store.dispatch(method, {slug: id, data: data.data}).then(() => {
          this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
          this.editedItem = {};
          this.dialog = !this.dialog;
          bus.$emit("moduleImagesDeletedByModal");
        });
      }
    },
    deleteItem(item) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?",
        msg: "You want to delete '" + item.title + "'?",
        clr: "red",
        callback: function () {
          let id = item.id;
          this.$store
              .dispatch(DELETE_FILTERVALUE, {
                filtervalue_id: id
              })
              .then(() => {
                that.loadData().then(response => {
                  that.items = response.items;
                  that.totalRecords = response.total;
                });
              });
        }
      });
    },
    getFilters() {
      this.$store.dispatch(FILTERS_SIMPLE_LIST).then(data => {
        this.filtersFilter = data.records.filtersList;
      });
    }
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.filtervalues.errors
    })
  }
};
</script>
